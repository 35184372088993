<template>
  <CModal :visible="visible" backdrop="static" size="lg" @close="close(false)">
    <CModalHeader>
      <CModalTitle>
      <span v-if="create">新建</span>
      <span v-else>编辑</span>
      {{ object_name }}
      </CModalTitle>
    </CModalHeader>
    <CModalBody>
    <div v-for="(f, i) in fields" :key="i" class="mb-2">
      <label>{{ f.metadata.verbose_name }}:</label>
      <RelatedFieldEditor v-if="f.metadata.type == 'related'" v-model="cur_obj[f.name]" :field_schema="f" />
      <textarea :placeholder="'请输入' + f.metadata.verbose_name" rows=5 class="form-control" v-model="cur_obj[f.name]" v-else-if="f.metadata.longtext" :id="f.name"></textarea>
      <div v-else-if="f.metadata.richtext">
        <RichTextEditor v-model="cur_obj[f.name]" />
      </div>
      <input type="checkbox" class="form-check-input mx-3" v-else-if="f.metadata.type=='boolean'" v-model="cur_obj[f.name]" :id="f.name"/>
      <input v-else :placeholder="'请输入' + f.metadata.verbose_name" class="form-control" type="text" v-model="cur_obj[f.name]" :id="f.name"/>
    </div>
    </CModalBody>
    <CModalFooter>
      <button type="button" class="btn btn-secondary" @click="close(flase)" id="cancel-btn">
        取消
      </button>
      <button type="button" class="btn btn-primary" @click="save" id="submit-btn">
        提交
      </button>
    </CModalFooter>
  </CModal>
</template>

<script>
import RichTextEditor from './richtext-editor'
import RelatedFieldEditor from './related-field-editor'

export default {
  name: 'GenericEditModal',
  props: ['fields', 'id', 'create', 'object_name', 'uri', 'pre_save'],
  components: {
    RichTextEditor,
    RelatedFieldEditor,
  },
  data: function () {
    return {
      cur_obj: {},
      error: '',
      visible: false,
    };
  },
  computed: {
    field_names: function() {
      return this.fields.map(x => x.name);
    }
  },
  methods: {
    get_field_metadata(f) {
      for (var x of this.fields) {
        if (x.name == f) return x.metadata;
      }
    },
    save: async function () {
      var data = this.cur_obj;
      for (var x in data) {
        var fm = this.get_field_metadata(x);
        if (fm && fm.type == 'related' && data[x] && data[x].resource_uri) {
          data[x] = data[x].resource_uri;
        }
        if (x == 'id' || this.field_names.includes(x)) {
          continue;
        }
        delete data[x];
      }
      if (this.pre_save) {
        data = this.pre_save(data);
      }
      console.log(data);
      try {
        if (this.create) {
          await this.$root.api_post(this.uri, data);
        } else {
          await this.$root.api_patch(this.uri, data);
        }
        this.close(true);
      } catch (e) {
        this.error = e;
      }
    },
    set_obj: function(obj) {
      this.cur_obj = obj;
    },
    initialize: function() {
      var new_obj = {};
      for (var f of this.fields) {
        if (f.default_value) {
          new_obj[f.name] = f.default_value();
        }
      }
      this.cur_obj = new_obj;
    },
    show: function() {
      this.visible = true;
    },
    close: function(ok) {
      this.visible = false;
      this.$emit('close', ok);
    },
  },
  mounted() {
  },
}
</script>

<style scoped>
</style>

